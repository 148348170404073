import React from "react";
import { PageProps } from "gatsby";
import { Helmet } from "react-helmet";
import { Container, Row, Col, Button } from "react-bootstrap";
import { Layout } from "../components/Layout";
import { Map } from "../components/map/Map";

export default function Example(props: PageProps) {
    return (
        <Layout pageProps={props}>
            <Helmet defer={false}>
                <title>Example</title>
            </Helmet>

            <Container >
                <Row>
                    <Col className="d-flex justify-content-start">
                        <h1>
                            Example Page
                        </h1>
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <Map />
                    </Col>
                </Row>
            </Container>
        </Layout>
    );
}